import { AxiosResponse } from "axios";
import {
  CancelSubscriptionApiResponse,
  CancelSubscriptionRequestPayload,
  DownloadSubscriptionInvoiceApiResponse,
  ManageRefundRequestApiResponse,
  ManageRefundRequestPayload,
  UpdateSubscriptionDaysApiResponse,
  UpdateSubscriptionDaysRequestPayload,
  UpdateSubscriptionStartDateApiResponse,
  UpdateSubscriptionStartDateRequestPayload,
} from "app/types";
import { getJwtDetails } from "./authService";
import axios from "./config";
import {
  MealHistoryApiResponse,
  PlannedOrderLogsApiResponse,
  SubscriptionHistoryApiResponse,
} from "app/types/subscriptionsManager";

export const getSubscriptionsAPI = (payload: any) =>
  axios.get(`/v2/subscriptions${payload}`);

export const updateStatusAPI = (
  payload: { progress: string },
  orderId: number
) => axios.post(`/express-orders/${orderId}/events`, payload);

export const getSubscriptionDetailAPI = (payload: any) =>
  axios.get(`/subscriptions${payload}?v=2`);

export const getSubscriptionLimitAPI = (storeId: number) =>
  axios.get(`/stores/${storeId}/limits`);

export const orderCreateAPI = (payload: any) => {
  const { userId }: any = getJwtDetails() || {};
  return axios.post(`/users/${userId}/Orders`, payload);
};

export const orderUpdateAPI = (payload: any, orderId: any) => {
  return axios.put(`orders/${orderId}`, payload);
};

export const getOrderAPI = (orderId: any) => {
  const { userId }: any = getJwtDetails() || {};
  return axios.get(`/users/${userId}/Orders/${orderId}`);
};

export const getOrderRequestAPI = (orderId: any) => {
  return axios.get(`/orders/${orderId}`);
};

export const checkoutsAPI = (payload: any, orderId: any, brand: any) => {
  const { userId }: any = getJwtDetails() || {};
  return axios.post(`/v2/users/${userId}/Orders/${orderId}/payments`, payload, {
    headers: {
      Application: brand,
    },
  });
};

export const applyCouponCodeAPI = (payload: any, orderId: any) => {
  const { userId }: any = getJwtDetails() || {};
  return axios.post(`/users/${userId}/Orders/${orderId}/promotions`, payload);
};

export const deleteCouponCodeAPI = (orderId: any) => {
  const { userId }: any = getJwtDetails() || {};
  return axios.delete(`/users/${userId}/Orders/${orderId}/promotions`);
};

export const getDeliveryShiftsAPI = (storeId: number) =>
  axios.get(`/stores/${storeId}/delivery-shifts`);

export const getSummaryOrdersAPI = (payload: any) => {
  const { subscriptionId, fromDate, toDate } = payload;
  return axios.get(
    `subscriptions/${subscriptionId}/summary-orders?fromDate=${fromDate}&toDate=${toDate}`
  );
};

export const getPackageMealAPI = (payload: any) => {
  const { packageId, date, portionId, query } = payload;
  return axios.get(
    `v2/packages/${packageId}/meals/${date}?portionId=${portionId}&v=2${query}`
  );
};

export const getPlannedOrdersAPI = (payload: any) => {
  const { subscriptionId, date } = payload;
  return axios.get(`subscriptions/${subscriptionId}/planned-orders/${date}`);
};

export const putDeliveryScheduleAPI = (payload: any) => {
  const { clientId, data } = payload;
  return axios.put(`clients/${clientId}/delivery-schedules`, data);
};
export const getDeliveryScheduleAPI = (clientId: any) => {
  return axios.get(`clients/${clientId}/delivery-schedules`);
};

export const createPlannedOrderAPI = (data: any) => {
  const { subscriptionId, payload } = data;
  return axios.post(`subscriptions/${subscriptionId}/planned-orders`, payload);
};

export const addPlannedItemAPI = (data: any) => {
  const { subscriptionId, payload, plannedId } = data;
  return axios.post(
    `subscriptions/${subscriptionId}/planned-orders/${plannedId}/planned-items`,
    payload
  );
};

export const getPauseDaysAPI = (subscriptionId: number) => {
  return axios.get(`Subscriptions/${subscriptionId}/paused-subscriptions`);
};

export const pauseSubscriptionAPI = (data: any) => {
  const { clientid, payload } = data;
  return axios.post(`clients/${clientid}/paused-subscriptions`, payload);
};

interface Freeze {
  clientId: number;
  payload: {
    type: "Freeze";
    startAt: string;
  };
}

export const freezeSubscriptionAPI = (data: Freeze) => {
  const { clientId, payload } = data;
  return axios.post(`clients/${clientId}/paused-subscriptions`, payload);
};
export const unfreezeSubscriptionAPI = (payload: any) => {
  const { clientId, startAt } = payload;
  return axios.delete(`clients/${clientId}/paused-subscriptions/${startAt}`);
};
export const connectAppAPI = (payload: {
  clientId: number;
  password: string;
  application: string;
}) => {
  return axios.post(`client-users`, payload);
};

export const cashPaymentAPI = (data: any) => {
  const { orderId, paymentId } = data;
  return axios.post(
    `v2/orders/${orderId}/paymentRequests/${paymentId}/manualPayments`,
    {}
  );
};

export const updatePlannedOrderAPI = (payload: any) => {
  const { subscriptionId, plannedId, data } = payload;

  return axios.patch(
    `subscriptions/${subscriptionId}/planned-orders/${plannedId}`,
    data
  );
};

export const getRequestAPI = (paylaod: any) =>
  axios.get(`/subscription-requests${paylaod}`);

export const getPortionsAPI = () => axios.get(`/portions`);

export const updateSubscriptionMealAPI = (payload: any) => {
  const { subscriptionId, meals } = payload;
  const body = { meals: meals };
  return axios.put(`subscriptions/${subscriptionId}/meals`, body);
};

export const getPlannedOrderLogsApi = (
  plannedOrderId: number,
  query = ""
): Promise<AxiosResponse<PlannedOrderLogsApiResponse>> =>
  axios.get<PlannedOrderLogsApiResponse>(
    `/planned-orders/${plannedOrderId}/logs${query}`
  );

export const getMealHistoryApi = (
  plannedOrderId: number
): Promise<AxiosResponse<MealHistoryApiResponse>> =>
  axios.get<MealHistoryApiResponse>(
    `/planned-orders/${plannedOrderId}/meal-history`
  );

export const updateSubscriptionDaysApi = (
  id: number,
  paylaod: UpdateSubscriptionDaysRequestPayload
): Promise<AxiosResponse<UpdateSubscriptionDaysApiResponse>> => {
  return axios.put<UpdateSubscriptionDaysApiResponse>(
    `/subscriptions/${id}/subscription-days`,
    paylaod
  );
};

export const updateSubscriptionStartDateApi = (
  id: number,
  paylaod: UpdateSubscriptionStartDateRequestPayload
): Promise<AxiosResponse<UpdateSubscriptionStartDateApiResponse>> => {
  return axios.put<UpdateSubscriptionStartDateApiResponse>(
    `/subscriptions/${id}/start-date`,
    paylaod
  );
};
export const downloadSubscriptionInvoiceApi = (
  id: string
): Promise<AxiosResponse<DownloadSubscriptionInvoiceApiResponse>> => {
  return axios.get<DownloadSubscriptionInvoiceApiResponse>(
    `/orders/${id}/invoices`
  );
};
export const getSubscriptionLogsApi = (
  subscriptionId: number,
  query = ""
): Promise<AxiosResponse<SubscriptionHistoryApiResponse>> => {
  return axios.get<SubscriptionHistoryApiResponse>(
    `/subscriptions/${subscriptionId}/logs${query}`
  );
};

export const cancelSubscriptionApi = (
  subscriptionId: number,
  paylaod: CancelSubscriptionRequestPayload
): Promise<AxiosResponse<CancelSubscriptionApiResponse>> => {
  return axios.post<CancelSubscriptionApiResponse>(
    `/subscriptions/${subscriptionId}/status`,paylaod
  );
};

export const subscriptionRefundRequestApi = (
  clientId: number,
  paylaod: ManageRefundRequestPayload
): Promise<AxiosResponse<ManageRefundRequestApiResponse>> => {
  return axios.post<ManageRefundRequestApiResponse>(
    `/clients/${clientId}/refund-requests`,paylaod
  );
};
