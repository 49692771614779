import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import { PaginationInterfaceLF } from "app/types";
import { RootState } from "../reducers/hooks";
import {
  getMealHistoryApi,
  getPlannedOrderLogsApi,
  getSubscriptionLogsApi,
} from "app/services/subscriptions";
import { MealHistory, PlannedOrderLog, SubscriptionLog } from "app/types/subscriptionsManager";

interface InitialState {
  plannedOrderLogs: PlannedOrderLog[];
  subscriptionLogs: SubscriptionLog[];
  subscriptionLogsPagination: PaginationInterfaceLF;
  plannedOrderLogsPagination: PaginationInterfaceLF;
  plannedOrderLogListRowPerPage: number;
  subscriptionLogsListRowPerPage: number;
  isPlannedOrderLogLoading: boolean;
  mealHistoryItems: MealHistory[];
  isMealHistoryLoading: boolean;
  isSubscriptionLogsLoading: boolean
}

const initialState: InitialState = {
  plannedOrderLogs: [],
  subscriptionLogs: [],
  subscriptionLogsPagination: null,
  plannedOrderLogsPagination: null,
  plannedOrderLogListRowPerPage: 20,
  subscriptionLogsListRowPerPage: 20,
  isPlannedOrderLogLoading: false,
  mealHistoryItems: [],
  isMealHistoryLoading: false,
  isSubscriptionLogsLoading: false,
};

export const subscriptionsManagerSlice = createSlice({
  name: "subscriptionsManager",
  initialState,
  reducers: {
    setPlannedOrderLogs: (
      state,
      action: PayloadAction<{
        logs: PlannedOrderLog[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.plannedOrderLogs = action.payload.logs;
      state.plannedOrderLogsPagination = action.payload.pagination;
    },
    setSubscriptionLogs: (
      state,
      action: PayloadAction<{
        logs: SubscriptionLog[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.subscriptionLogs = action.payload.logs;
      state.subscriptionLogsPagination = action.payload.pagination;
    },
    setPlannedOrderLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPlannedOrderLogLoading = action.payload;
    },
    setSubscriptionLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSubscriptionLogsLoading = action.payload;
    },
    setPlannedOrderLogListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.plannedOrderLogListRowPerPage = action.payload;
    },
    setSubscriptionLogListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.subscriptionLogsListRowPerPage = action.payload;
    },
    setMealHistoryItems: (
      state,
      action: PayloadAction<{
        items: MealHistory[];
      }>
    ) => {
      state.mealHistoryItems = action.payload.items;
    },
    setMealHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isMealHistoryLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlannedOrderLogs,
  setSubscriptionLogs,
  setPlannedOrderLogsLoading,
  setPlannedOrderLogListRowPerPage,
  setSubscriptionLogsLoading,
  setSubscriptionLogListRowPerPage,
  setMealHistoryItems,
  setMealHistoryLoading,
} = subscriptionsManagerSlice.actions;

export default subscriptionsManagerSlice.reducer;

export const getPlannedOrderLogsAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  plannedOrdersId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { plannedOrderLogListRowPerPage } = getState().subscriptionsManager;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: plannedOrderLogListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setPlannedOrderLogsLoading(true));
      const { data } = await getPlannedOrderLogsApi(plannedOrdersId, query);
      dispatch(setPlannedOrderLogsLoading(false));
      const logs = data.data;
      const users = data.included.users;
      const pagination = data.pagination;

      // Update the logs to add the user object to log object in case of "userId" != 0
      const updatedLogs = logs.map((log) => {
        const user = users.find((user) => user.id === log.userId);
        return {
          ...log,
          user,
        };
      });

      // Dispatch the updated logs to the state
      dispatch(
        setPlannedOrderLogs({
          logs: updatedLogs,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getSubscriptionLogsAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  subscriptionId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { subscriptionLogsListRowPerPage } = getState().subscriptionsManager;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: subscriptionLogsListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setSubscriptionLogsLoading(true));
      const { data } = await getSubscriptionLogsApi(subscriptionId, query);
      dispatch(setSubscriptionLogsLoading(false));
      const logs = data.data;
      const users = data.included.users;
      const pagination = data.pagination;

      // Update the logs to add the user object to log object in case of "userId" != 0
      const updatedLogs = logs.map((log) => {
        const user = users.find((user) => user.id === log.userId);
        return {
          ...log,
          user,
        };
      });

      // Dispatch the updated logs to the state
      dispatch(
        setSubscriptionLogs({
          logs: updatedLogs,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};
export const getMealHistoryAction = (plannedOrdersId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setMealHistoryLoading(true));
      const { data } = await getMealHistoryApi(plannedOrdersId);
      dispatch(setMealHistoryLoading(false));
      const mealHistoryItems = data.data;
      const users = data.included.users;
      const meals = data.included.meals;

      // Update the mealHistoryItems to add the user & meal object to each item
      const updatedMealHistoryItems = mealHistoryItems.map((item) => {
        const user = users.find((user) => user.id === item.userId);
        const mealInfo = meals.find((meal) => meal.mealId === item.mealId);
        return {
          ...item,
          user,
          mealInfo,
        };
      });

      // Dispatch the updated mealHistoryItems to the state
      dispatch(
        setMealHistoryItems({
          items: updatedMealHistoryItems,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};
